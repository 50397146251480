<template>
  <footer>
    <div class="footer p--m" :class="{ 'white': route.path == '/style' || route.path == '/room', 'blue': route.path == '/result' }" v-if="pageData" ref="footerRef">
      <div class="footer__col">
        <span class="footer__copyright roboto--300">© {{ pageData.content.start_footer_copyright }} {{ new Date().getFullYear() }}</span>
      </div>
      <div class="footer__col">
        <div class="footer__nav roboto--300" v-if="navItems">
          <button @click="openLangOverlay">Sprache wechseln</button>
          <a v-for="item in navItems" :href="item.nav_url" target="_blank">{{ item.nav_title }}</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import {doQuery, getPageQuery, getStartQuery} from "@/utils/queries";
import {ref, onMounted, onUpdated, watch} from "vue";
import yaml from "js-yaml";

const route = useRoute();

import {useGeneralStore} from "@/stores/general";
import { useRoute } from "vue-router";
const generalStore = useGeneralStore();

import {usePagesStore} from "@/stores/pages";
const pagesStore = usePagesStore();

import {useLanguageStore} from "@/stores/language";
const languageStore = useLanguageStore();

const footerRef = ref(null);
const pageData = ref(null);
const navItems = ref([]);

const openLangOverlay = () => {
  languageStore.isLangOverlayOpen = true;
};

const outsourcedQueryData = async (changedLang = false) => {
  if(pagesStore.pagesData && pagesStore.pagesData['start'] && !changedLang) {
    console.log('QUERY DATA TRIGGERED IF');
    pageData.value = pagesStore.pagesData['start'];
  } else {
    console.log('QUERY DATA TRIGGERED ELSE');
    const pageStartQuery = getStartQuery("Start");
    pageData.value = await doQuery(pageStartQuery);
    pagesStore.pagesData['start'] = pageData.value;
  }

  if(pageData.value) navItems.value = yaml.load(pageData.value.content.start_footer_nav);
};

// onMounted(async () => {
//   console.log('FOOTER VUE MOUNTED')
//   // if(pagesStore.pagesData && pagesStore.pagesData['start']) {
//   //   pageData.value = pagesStore.pagesData['start'];
//   // } else {
//   //   console.log('LOAD THE DATA IN FOOTER');
//   //   const pageStartQuery = getStartQuery("Start");
//   //   pageData.value = await doQuery(pageStartQuery);
//   //   pagesStore.pagesData['start'] = pageData.value;
//   // }
//   //
//   // if(pageData.value) {
//   //   navItems.value = yaml.load(pageData.value.content.start_footer_nav);
//   // }
//   await outsourcedQueryData(false);
// });

outsourcedQueryData(false);

onUpdated(() => {
  if (footerRef.value) generalStore.footerHeight = footerRef.value.clientHeight;
});

watch(languageStore, async () => {
  console.log('FOOTER VUE LANGUAGE WATCHER');
  await outsourcedQueryData(true);
});
</script>

<style scoped lang="scss">
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  border-top: 1px solid var(--vb_grey_mid);
  background-color: var(--vb_grey_light);
  width: 100%;

  .footer__col {
    .footer__copyright {
      color: var(--vb_grey_dark);
    }
    .footer__nav {
      display: flex;
      gap: 16px;
      a {
        color: var(--vb_grey_dark);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.white {
    background-color: var(--vb_white);
  }

  &.blue {
    background-color: var(--vb_blue_light);
  }
}
@media screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
    align-items: flex-start;
    .footer__col {
      margin: 0 0 8px 0;
      &:nth-last-child(1) {
        margin: 0;
      }
    }
  }
}
</style>