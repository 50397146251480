<template>
  <div class="svg" :style="getMaxWidth()">
    <img :src="`/svgs/${props.path}/${props.svg}.svg`" alt="SVG" v-if="props.svg !== 'icon_heart'">
    <div v-else>
      <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.3564 5.02078L16.4145 4.97808L16.4581 4.92071C18.1596 2.68411 20.2001 1.10006 22.682 1.09998C23.336 1.10055 23.9857 1.20496 24.607 1.40931C26.1409 1.94946 27.4623 2.96538 28.3786 4.30907C29.2958 5.65409 29.7589 7.2571 29.7005 8.88402L29.7002 8.893V8.90198C29.7002 12.1475 27.6897 14.4012 26.2435 16.0224C25.9811 16.3165 25.7373 16.5899 25.5274 16.8445L16.7337 26.0996L16.7213 26.1126L16.71 26.1264C16.5629 26.3053 16.378 26.4495 16.1687 26.5487C15.9597 26.6477 15.7314 26.6993 15.5002 26.7C15.2689 26.6993 15.0406 26.6477 14.8316 26.5487C14.6223 26.4495 14.4375 26.3053 14.2904 26.1264L14.279 26.1126L14.2666 26.0996L5.47295 16.8445C5.26466 16.5918 5.02289 16.3203 4.76268 16.0281C3.31624 14.4039 1.30017 12.14 1.30017 8.90198V8.893L1.29985 8.88402C1.24139 7.2571 1.70458 5.65409 2.62175 4.30907C3.53801 2.96538 4.85942 1.94946 6.39336 1.40931C7.01461 1.20496 7.66436 1.10055 8.31837 1.09998C10.8002 1.10006 12.8408 2.68411 14.5422 4.92071L14.5859 4.97808L14.644 5.02078C14.8915 5.20279 15.1945 5.29188 15.5002 5.27345C15.8058 5.29188 16.1089 5.20279 16.3564 5.02078Z" fill="#023E84" stroke="#023E84"/>
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import {onMounted, ref, watchEffect} from 'vue';

const props = defineProps({
  path: {
    type: String,
    required: true
  },
  svg: {
    type: String,
    required: true
  },
  max: {
    type: String,
    default: 'auto'
  }
});

const svgContent = ref('');

const getMaxWidth = () => {
  return props.max === 'auto' ? 'max-width: 100%' : `max-width: ${props.max}px`;
};

// watchEffect(async () => {
//   const svgModulePath = `/svgs/${props.path}/${props.svg}.svg`;
//   try {
//     const svg = await import(/* @vite-ignore */ svgModulePath);
//     fetch(svg.default)
//         .then(response => response.text())
//         .then(data => {
//           svgContent.value = data;
//         });
//   } catch (error) {
//     console.error("Failed to load SVG:", error);
//   }
// });

// onMounted(() => {
//   const svgModules = import.meta.glob('/svgs/**/*.svg', { query: '?raw' });
// });
//
// watchEffect(async () => {
//   const svgModulePath = `/svgs/${props.path}/${props.svg}.svg`;
//   try {
//     const svg = await svgModules[svgModulePath]();
//     console.log(svg);
//     svgContent.value = svg;
//   } catch (error) {
//     console.error("Failed to load SVG:", error);
//   }
// });

// watchEffect(async () => {
//   const svgUrl = `/svgs/${props.path}/${props.svg}.svg`;
//   try {
//     const response = await fetch(svgUrl);
//     if (!response.ok) {
//       throw new Error(`Failed to fetch SVG: ${response.statusText}`);
//     }
//     const data = await response.text();
//     svgContent.value = data;
//   } catch (error) {
//     console.error("Failed to load SVG:", error);
//   }
// });
</script>

<style scoped lang="scss">
.svg {}
</style>