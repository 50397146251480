import { ref, onMounted, onUnmounted } from 'vue';

const isMobile = ref(window.innerWidth <= 1024);

const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 1024;
};

export const useIsMobile = () => {
    onMounted(() => {
        window.addEventListener('resize', updateIsMobile);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateIsMobile);
    });

    return { isMobile };
};