export function addToLocalStorage(key,data) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function getFromLocalStorage(key) {
    if(!localStorage.getItem(key)) return null;
    return localStorage.getItem(key);
}

export function removeFromLocalStorage(key) {
    localStorage.removeItem(key);
}