export function datalayerPush(step = "", page = "", location = document.referrer, action = "", event = "tool") {
    const args = {};
    if(step) args.step = step;
    if(page) args.page = page;
    if(action) args.action = action;
    if(location && step === 'started') args.location = location;
    if(event) args.event = event;
    if (event != 'virtualpageview') {
        args.category = 'bathroom designer';
    }
    const data = {
        ...args
    };
    console.log('dataLayer.push', data);
    console.log('dataLayer', window.dataLayer);
    if(window.dataLayer) window.dataLayer.push(data);
}