import { createRouter, createWebHistory } from 'vue-router'
// import StartView from '../views/01_StartView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'start',
      component: () => import('../views/01_StartView.vue')
    },
    {
      path: '/style',
      name: 'style',
      component: () => import('../views/02_StyleView.vue')
    },
    {
      path: '/look',
      name: 'look',
      component: () => import('../views/03_LookView.vue')
    },
    {
      path: '/room',
      name: 'room',
      component: () => import('../views/04_RoomView.vue')
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('../views/05_ResultView.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/06_RegisterView.vue')
    },
    {
      path: '/debug',
      name: 'debug',
      component: () => import('../views/07_DebugView.vue')
    }
  ]
})

export default router
