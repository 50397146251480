import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useRoutesStore = defineStore('routes', () => {
    const navRoutes = [
        {name: 'style', path: '/style', title: '1. Stil'},
        {name: 'look', path: '/look', title: '2. Look'},
        {name: 'room', path: '/room', title: '3. Raumgröße'},
        {name: 'result', path: '/result', title: 'Ihr Ergebnis'},
    ];
    // const addedBookmarks = ref([]);
    const allowedRoutes = ref([]);
    const finishedRoutes = ref([]);
    const toolHasStarted = ref(false);
    return { navRoutes, allowedRoutes, finishedRoutes };
})