import {useRoutesStore} from "@/stores/routes";
export function isAFinishedRoute(key) {
    const routesStore = useRoutesStore();
    return routesStore.finishedRoutes.some(route => route.hasOwnProperty(key));
}

export function isAnAllowedRoute(key) {
    const routesStore = useRoutesStore();
    return routesStore.allowedRoutes.some(route => route.name === key);
}

export function setAllowedRoutes() {
    const routesStore = useRoutesStore();
    routesStore.allowedRoutes = [];
    for (let i = 0; i <= routesStore.finishedRoutes.length; i++) {
        if (!routesStore.allowedRoutes.some(object => object.name === routesStore.navRoutes[i].name)) {
            routesStore.allowedRoutes.push(routesStore.navRoutes[i]);
        }
    }
}
export function currentRouteIndex(name) {
    const routesStore = useRoutesStore();
    return routesStore.navRoutes.findIndex(route => route.name === name);
}