import {addToLocalStorage, getFromLocalStorage} from "@/utils/localstorage";

import {useConfigurationStore} from "@/stores/configuration";
import {useRoutesStore} from "@/stores/routes";

function removeAllItemsFromArrayBeginningFromIndex(array, index) {
    array.splice(index, array.length);
}

export function addToConfiguration(key, data) {
    const configurationStore = useConfigurationStore();
    const routesStores = useRoutesStore();

    if (!data) return console.error('No data provided');
    if (!key) return console.error('No key provided');
    const index = configurationStore.bathroomConfig.findIndex(obj => key in obj);
    if (index !== -1) {
        // console.log('Updating existing configuration');
        configurationStore.bathroomConfig[index][key] = data;
        removeAllItemsFromArrayBeginningFromIndex(configurationStore.bathroomConfig, index + 1);
    } else {
        // console.log('Adding new configuration');
        configurationStore.bathroomConfig.push({ [key]: data });
    }
    addToLocalStorage('bathroomConfig', configurationStore.bathroomConfig);
    routesStores.finishedRoutes = configurationStore.bathroomConfig;
}

export function setBathroomConfiguration() {
    const configurationStore = useConfigurationStore();
    const routesStores = useRoutesStore();

    if (configurationStore.bathroomConfig.length <= 0 && getFromLocalStorage('bathroomConfig')) {
        configurationStore.bathroomConfig = JSON.parse(getFromLocalStorage('bathroomConfig'));
    } else {
        configurationStore.bathroomConfig = [];
    }

    routesStores.finishedRoutes = configurationStore.bathroomConfig;
}

export function hasBathroomConfiguration() {
    const configurationStore = useConfigurationStore();
    // console.log('hasBathroomConfiguration', configurationStore.bathroomConfig.length > 0);
    return configurationStore.bathroomConfig.length > 0;
}

export function resetBathroomConfiguration() {
    const configurationStore = useConfigurationStore();
    const routesStores = useRoutesStore();

    configurationStore.bathroomConfig = [];
    routesStores.finishedRoutes = configurationStore.bathroomConfig;
    addToLocalStorage('bathroomConfig', configurationStore.bathroomConfig);
}